<template>
  <div class="main">
    <div class="main-top">
      <img @click="goBack" src="@/assets/image/arrow-left.png" />
      <span>信用明细</span>
    </div>

    <div class="main-content" @scroll="handleScroll">
      <div class="content-item" v-for="(item, index) in list" :key="index">
        <div class="content-item-left">
          <div class="title">{{ item.description }}</div>
          <div class="time">{{ item.dateline }}</div>
        </div>
        <div
          :class="[
            'content-item-right',
            { 'content-item-right-minus': item.type !== '增加' }
          ]"
        >
          {{ item.type === "增加" ? "+ " : "- " }}{{ item.quantity }}
        </div>
      </div>

      <div class="isBottom">
        {{ isBottom ? "我也是有底线的" : "触底刷新更多~" }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      list: [],
      flag: true,
      isBottom: false
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async getList() {
      if (localStorage.getItem("userid")) {
        let userid = localStorage.getItem("userid");
        const res = await this.$http.get(`/api/v1/Accounts/${userid}/Credits`, {
          params: {
            id: userid,
            page: this.page,
            size: 20
          }
        });
        if (res.status === 200) {
          console.log("res", res);

          if (res.data.list && res.data.list.length !== 0) {
            this.list = this.list.concat(res.data.list);
            this.flag = true;
            this.page++;
          } else {
            this.flag = false;
            this.isBottom = true;
          }
          if (res.data.list.length < 20) {
            this.flag = false;
            this.isBottom = true;
          }
        }
      } else {
        this.$router.push("/mobile");
      }
    },

    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 当滚动到底部时，距离顶部+自身高度 >= 总高度
      if (scrollTop + clientHeight >= scrollHeight) {
        // 触发刷新操作
        if (this.flag) {
          this.flag = false;
          console.log("触底刷新");
          this.getList();
        }
      }
    }
  },
  async created() {
    this.getList();
  }
};
</script>
<style lang="scss" scoped>
.main {
  min-height: calc(100vh - 40px);
  width: 100vw;
  background-color: #f8f8fa;
  position: relative;
  padding-top: 40px;
  .main-top {
    position: fixed;
    top: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #232323;
    font-size: 16px;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    background-color: #fff;
    img {
      width: 20px;
      position: absolute;
      left: 0;
      margin-left: 15px;
    }
  }
  .main-content {
    padding: 0 15px;
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 40px);
    overflow: auto;
    .content-item {
      width: 100%;
      padding: 19px 10px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      margin-top: 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .content-item-left {
        .title {
          color: #232323;
          font-size: 14px;
          font-weight: bolder;
        }
        .time {
          font-size: 12px;
          color: #999999;
          margin-top: 15px;
        }
      }
      .content-item-right {
        font-size: 16px;
        font-weight: bolder;
        color: #3fce14;
        flex-shrink: 0;
        margin-left: 10px;
      }
      .content-item-right-minus {
        color: #ffa200;
      }
    }
  }
  .isBottom {
    text-align: center;
    margin: 20px 0;
  }
}
</style>
